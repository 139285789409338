import * as React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import { SimpleLayout } from "../components/SimpleLayout"
import { HomeLanding } from "../components/HomeLanding"
import { HomeTeamSpecialists } from "../components/HomeTeamSpecialists"
import { HomePlans } from "../components/HomePlans"
import { SimpleFooter } from "../components/SimpleFooter"

const NewIndexPage = ({ data }) => {
  return (
    <SimpleLayout>
      <Helmet htmlAttributes={{ lang: "pl" }}>
        <meta charSet="utf-8" />
        <title>OakTeams</title>
        <meta
          name="description"
          content="Oakteams - create your best software team"
        />
      </Helmet>
      <HomeLanding />
      <HomeTeamSpecialists />
      <HomePlans />
    </SimpleLayout>
  )
}

export default NewIndexPage
