import React from "react"
import { Link } from "gatsby"
import { Container, Headers, Header, Content, Section } from "./style"

export const Footer = () => {
  return (
    <Container>
      <Headers>
        <Header>Quick links</Header>
        <Header>Office Poland</Header>
        <Header>Austria</Header>
        <Header>Nordic</Header>
      </Headers>
      <Content>
        <Section>
          <Header>Quick links</Header>
          <Link to="/#offer">Offer</Link>
          <Link to="/#technologies">Technologies</Link>
          <Link to="/#our-process">Process</Link>
          <Link to="/case-studies">Case studies</Link>
        </Section>
        <Section>
          <Header>Office Poland</Header>
          <p>Piłsudskiego 43, 50-032 Wrocław, Poland</p>
          <a href="mailto:office@oakfusion.pl">office@oakfusion.pl</a>
          <p>+48 797 743 064</p>
        </Section>
        <Section>
          <Header>Austria</Header>
          <p>c/o Impact Hub Vienna Lindengasse 56/18-19 1070 Wien, Austria</p>
          <p>vienna@oakfusion.com</p>
          <p>+43 69919064248</p>
        </Section>
        <Section>
          <Header>Nordic</Header>
          <p>Anckargripsgatan 3 211 19-SE Malmö Sweden</p>
          <a href="mailto:office@oakfusion.pl">office@oakfusion.pl</a>
        </Section>
      </Content>
    </Container>
  )
}
