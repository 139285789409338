import React, { useState } from "react"
import {
  Container,
  Links,
  StyledLink,
  DesktopWrapper,
  MobileWrapper,
  Burger,
  Logo,
  MobileLogo,
} from "./style"
import { Button } from "../Button"
import { StaticImage } from "gatsby-plugin-image"
import { BurgerMenu } from "../BurgerMenu"

export const Navbar = () => {
  const [isBurgerActive, setBurgerActive] = useState(false)
  const isActive = ({ href, location }) => {
    return href === location.pathname + location.hash
      ? {
          style: {
            borderBottom: "1.5px solid #2f1bac",
            color: "#2F1BAC",
            marginBottom: "-0.75px",
          },
        }
      : {}
  }
  return (
    <Container>
      <DesktopWrapper>
        <Logo to="/">
          <StaticImage
            src="../../images/oakteams-logo.png"
            alt="logo"
            height={32}
            placeholder="blurred"
          />
        </Logo>
        <Links>
          <StyledLink getProps={isActive} text="Offer" to="/#offer">
            Offer
          </StyledLink>
          <StyledLink
            getProps={isActive}
            text="Technologies"
            to="/#technologies"
          >
            Technologies
          </StyledLink>
          <StyledLink getProps={isActive} text="Process" to="/#our-process">
            Process
          </StyledLink>
          <StyledLink
            getProps={isActive}
            text="Case studies"
            to="/case-studies/"
          >
            Case studies
          </StyledLink>
          {/* <StyledLink getProps={isActive} text="Blog" to="/blog/">
            Blog
          </StyledLink> */}
        </Links>
        <Button to="/#contact-us" text="Contact" />
      </DesktopWrapper>
      <MobileWrapper>
        <MobileLogo to="/">
          <StaticImage
            src="../../images/oakteams-logo.png"
            alt="logo"
            height={24}
            placeholder="blurred"
          />
        </MobileLogo>
        {!isBurgerActive ? (
          <Burger onClick={() => setBurgerActive(true)}>
            <StaticImage
              src="../../images/burger.png"
              alt="burger"
              width={16}
              placeholder="blurred"
            />
          </Burger>
        ) : (
          <>
            <Burger onClick={() => setBurgerActive(false)}>
              <StaticImage
                src="../../images/close-button.png"
                alt="close button"
                width={16}
                placeholder="blurred"
              />
            </Burger>
            <BurgerMenu setBurgerActive={setBurgerActive} />
          </>
        )}
      </MobileWrapper>
    </Container>
  )
}
