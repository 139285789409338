import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  margin-top: 72px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: #212529;
  padding: 0 1.5px;
  border-bottom: 1.5px solid #fff;
  &:before {
    display: block;
    content: attr(text);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:active {
    color: #2f1bac;
    border-bottom: 1.5px solid #2f1bac;
    margin-bottom: -0.75px;
  }
  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `}
`

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 46px;
  flex-direction: column;
  justify-self: center;
  padding: 48px 0;
`
