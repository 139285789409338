import styled from "styled-components"

export const Container = styled.footer`
  height: 300px;
  width: 100%;
  @media (max-width: 890px) {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
`

export const Header = styled.h4`
  margin-bottom: 22px;
  display: none;
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 890px) {
    width: 80%;
    & > ${Header} {
      display: block;
    }
  }
`

export const Headers = styled.div`
  padding: 0 10% 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: space-between;
  & > ${Header} {
    display: block;
  }
  @media (max-width: 890px) {
    & > ${Header} {
      display: none;
    }
  }
`

export const Content = styled.div`
  padding: 24px 10% 0;
  border-top: 1px solid black;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: space-between;
  @media (max-width: 890px) {
    padding: 40px 24px 64px;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
`
