import React from "react";
import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { Content } from "./style";
import { GlobalStyle } from "../../styles/GlobalStyles";

export const Layout = ({ children }) => {
  return (
    <div>
      <GlobalStyle />
      <Navbar />
      <Content>{children}</Content>
      <Footer />
    </div>
  );
};
