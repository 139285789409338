import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled.nav`
  height: 120px;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 10;
  @media (max-width: 890px) {
    height: 72px;
  }
`

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 890px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

export const Logo = styled(Link)`
  justify-self: start;
`

export const DesktopWrapper = styled.div`
  height: 120px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  justify-items: end;
  padding: 0 120px;
  @media (max-width: 890px) {
    display: none;
  }
`

export const Burger = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-direction: row;
  justify-self: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: #212529;
  padding: 0 1.5px;
  border-bottom: 1.5px solid #fff;
  &:before {
    display: block;
    content: attr(text);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  &:hover {
    color: #2f1bac;
  }
  &:active {
    color: #2f1bac;
    border-bottom: 1.5px solid #2f1bac;
    margin-bottom: -0.75px;
  }
  &:focus {
    color: #2f1bac;
    border-bottom: 1.5px solid #2f1bac;
    margin-bottom: -0.75px;
  }
`

export const MobileLogo = styled(Link)`
  position: absolute;
  top: 24px;
  left: 24px;
`
