import React from "react"
import { Container, Links, StyledLink } from "./style"
import { Button } from "../Button"

export const BurgerMenu = ({ setBurgerActive }) => {
  const isActive = ({ href, location }) => {
    return href === location.pathname + location.hash
      ? { style: { borderBottom: "1.5px solid #2f1bac", color: "#2F1BAC" } }
      : {}
  }
  return (
    <Container>
      <Links>
        <StyledLink
          center={+true}
          getProps={isActive}
          text="Offer"
          to="/#offer"
          onClick={() => setBurgerActive(false)}
        >
          Offer
        </StyledLink>
        <StyledLink
          center={+true}
          getProps={isActive}
          text="Technologies"
          to="/#technologies"
          onClick={() => setBurgerActive(false)}
        >
          Technologies
        </StyledLink>
        <StyledLink
          center={+true}
          getProps={isActive}
          text="Process"
          to="/#our-process"
          onClick={() => setBurgerActive(false)}
        >
          Process
        </StyledLink>
        <StyledLink
          center={+true}
          getProps={isActive}
          text="Case studies"
          to="/case-studies/"
          onClick={() => setBurgerActive(false)}
        >
          Case studies
        </StyledLink>
        {/* <StyledLink
          center={+true}
          getProps={isActive}
          text="Blog"
          to="/blog/"
          onClick={() => setBurgerActive(false)}
        >
          Blog
        </StyledLink> */}
        <Button
          center={+true}
          to="/#contact-us"
          text="Contact"
          setBurgerActive={setBurgerActive}
        />
      </Links>
    </Container>
  )
}
